import React, { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useItem, useGenderFormatMessage } from '../../hooks';
import { getImageUrl } from '../../utils/resourceLoader';
import { getQueryStringParams, setUrlParams, setPathnameParam, thumbnailsStorage, capitalizeFirstLetter } from '../../utils/urls';

import { Preloader } from 'cet-components-lib/dist/UI/Preloader';
import { SocialShare } from 'cet-components-lib/dist/Content/SocialShare';
import { useCetEvents, useDeviceData } from 'cet-components-lib/dist/hooks';

import Book from './Book';
import Course from './Course';
import Lo from './Lo';
import Nano from './Nano';

import HorizontalSwipeLayout from '../HorizontalSwipeLayout';
import AudienceForm from '../AudienceForm';
import FavoritesForm from '../FavoritesForm';
import TaskForm from '../TaskForm';
import Button from '../Button';
import Title from '../Title';

import styles from './Preview.module.scss';

const PreviewContent = ({ itemId, onClose, onInnerPopupChange, onItemReady, setPopupClass, hideButtons }) => {
  const intl = useIntl();
  const { locale } = intl;
  const { getGenderFormatMessage } = useGenderFormatMessage();
  const { isMobileView } = useDeviceData();
  const [innerPopup, setInnerPopup] = useState();
  const [lastInnerPopup, setLastInnerPopup] = useState();
  const [favoritesMode, setFavoritesMode] = useState('level3');
  const [itemLoadData, setItemLoadData] = useState({ isReady: false, isLoading: true, isErrorLoading: false });
  const history = useHistory();
  const { sendLearningEvent } = useCetEvents();
  const { item, isItemLoading, itemErrorLoading, reloadItem } = useItem(itemId);

  const { favorites, info, userBooks } = useSelector(({ user }) => ({
    favorites: user.favorites,
    info: user.info,
    userBooks: user.books && user.books[0]?.data
  }));

  const isFavoritesFormOnly = window.location.search.includes('fav');

  const updateInnerPopup = useCallback(
    innerPopup => {
      setInnerPopup(innerPopup);
      setPopupClass(false);
      if (innerPopup) {
        setLastInnerPopup(innerPopup);
        if (onInnerPopupChange) {
          onInnerPopupChange(itemId, innerPopup);
        }
      }
    },
    [itemId, onInnerPopupChange, setPopupClass]
  );

  useEffect(() => {
    let isActive = true;

    if (!innerPopup) {
      setTimeout(() => {
        if (isActive) {
          setLastInnerPopup(false);
          if (onInnerPopupChange) {
            onInnerPopupChange(itemId, false);
          }
        }
      }, 400);
    }

    return () => {
      isActive = false;
    };
  }, [innerPopup, itemId, onInnerPopupChange]);

  const handleInnerPopupClose = () => {
    updateInnerPopup();
  };

  const handleFavoritesClick = () => {
    if (item.isInFavorites) {
      alert('remove from favorites');
    } else {
      updateInnerPopup('favorites');
    }
  };

  const [isItemInFavorites, setItemIsInFavorites] = useState(false);

  const checkIsInFavorites = useCallback(() => {
    setItemIsInFavorites(false);
    if (itemId) {
      for (let i = 0; i < favorites.length; i++) {
        for (let j = 0; j < favorites[i].favorites.length; j++) {
          if (favorites[i].favorites[j].contentCatalogId === itemId) {
            setItemIsInFavorites(true);
          }
        }
      }
    }
  }, [favorites, itemId]);

  useEffect(() => {
    if (item && item.isActive) {
      checkIsInFavorites();
    }
  }, [item, checkIsInFavorites]);

  useEffect(() => {
    let isActive = true;
    setTimeout(() => {
      if (isActive) {
        setItemLoadData({
          isReady: !!item,
          isLoading: isItemLoading,
          isErrorLoading: itemErrorLoading
        });
        if (itemErrorLoading) {
          console.error(`${itemErrorLoading.name}: ${itemErrorLoading.message}`);
        }
      }
    }, 0);
    return () => {
      isActive = false;
    };
  }, [item, isItemLoading, itemErrorLoading]);

  useEffect(() => {
    if (itemLoadData && itemLoadData.isReady && onItemReady) {
      onItemReady(itemId);
    }
  }, [itemId, itemLoadData, onItemReady]);

  const handleFavoritesInnerPopupClose = () => {
    updateInnerPopup();

    if (favoritesMode === 'level2') {
      let historyState = setPathnameParam('item', null);
      if (historyState?.search) {
        historyState.search = historyState.search.replaceAll('?fav', '').replaceAll('&fav', '');
      }
      history.push(`${historyState.pathname}${historyState.search}`, historyState);
    }
  };

  const handleShareClick = () => {
    updateInnerPopup('share');
  };

  const handleAttachClick = () => {
    if (item.type === 'Course') {
      updateInnerPopup('audiences');
    } else {
      updateInnerPopup('task');
    }
  };

  const handleOnBannerError = event => {
    if (event.target.src.toLowerCase().indexOf(thumbnailsStorage) >= 0) {
      event.target.src = getImageUrl(item.thumbnailId, 666, 239, false);
    } else {
      event.target.src = '/bad-image.svg'; //'/bad-image.svg' '/bad_images/ex_parit.png' '/bad_images/ex_book.png'
    }
  };

  const handleOnBannerLoad = event => {
    try {
      event.target.classList.add(styles.banner_image_show);
    } catch (ex) { }
  };

  const onOpenClick = () => {
    if (item) {
      let bookInMyBooks;
      if (item.type === 'Book') {
        bookInMyBooks = userBooks?.find(book => book.externalContextId && book.externalContextId.toLocaleLowerCase() === item.documentId.toLocaleLowerCase());
      }
      let itemUrl = bookInMyBooks?.activityInfo?.currentPageUrl || item.contextURL;
      if (itemUrl) {
        if (item.type === 'Book') {
          const url = new URL(itemUrl);
          if (locale !== 'he') { 
            url.hostname = `${locale}.${url.hostname}`;
          }
          itemUrl = url.toString();
        }
        let link = document.createElement('a');
        link.href = itemUrl;
        if (item.isLoItem) {
          let searchParams = link.search;
          searchParams = getQueryStringParams(searchParams);
          searchParams.sitekey = 'nairobi';
          if (item?.itemDimensions?.audiences?.teachers) {
            searchParams.options = 'nobar';
          }
          searchParams = setUrlParams(searchParams).search;
          link.search = searchParams;
        }
        link.target = '_blank';
        link.click();

        const isWordpressType = item.type === 'Lo' && /^[0-9]+$/.test(item.externalContextId);
        let wordpressType = '';
        if (isWordpressType) {
          const url = new URL(itemUrl);
          wordpressType = capitalizeFirstLetter(url.hostname.replace('www.', '').split('.')[0]);
        }
        let optionalData = {
          interactionType: isWordpressType ? wordpressType : item.type
        };
        if (item.type === 'Lo' && !isWordpressType) {
          optionalData.contentAgeGroup = item.ageGrades;
          optionalData.contentSubject = item.disciplines;
        }
        let key = item.type === 'Book' || item.type === 'Course' || isWordpressType ? 'contentGroupingId' : 'loId';
        optionalData[key] = item.externalContextId;
        sendLearningEvent({
          verbType: 'launched',
          objectId: itemUrl,
          objectName: item.title,
          optionalData
        });
      }
    }
  };

  useEffect(() => {
    if (window.location.search.includes('fav')) {
      setFavoritesMode('level2');
      updateInnerPopup('favorites');
    }
  }, [updateInnerPopup]);

  function getOnAttachClickPropValue(typesArray = []) {
    let handleAttachClickValue =
      typesArray.filter(data => {
        return data?.icon === 'teacherRef' || data?.icon === 'teacherRef-lesson' || data?.icon === 'book';
      }).length > 0
        ? false
        : handleAttachClick;

    return handleAttachClickValue;
  }

  const renderItemTypeSwitch = itemData => {
    let { type, itemTypes } = itemData;
    let handleAttachClickProp = getOnAttachClickPropValue(itemTypes);
    let externalCourseTitleFieldName = 'title';
    let externalCourseSummaryFieldName = 'summary';

    switch (type) {
      case 'Course':
        return (
          <Course
            {...item}
            itemId={item.id}
            onShareClick={handleShareClick}
            onFavoritesClick={handleFavoritesClick}
            onAttachClick={handleAttachClickProp}
            onOpenClick={onOpenClick}
            isInFavorites={isItemInFavorites}
            onBannerError={handleOnBannerError}
            onBannerLoad={handleOnBannerLoad}
            hideButtons={hideButtons}
          />
        );

      case 'Book':
        return (
          <Book
            {...item}
            itemId={item.id}
            onShareClick={handleShareClick}
            onFavoritesClick={handleFavoritesClick}
            onOpenClick={onOpenClick}
            isInFavorites={isItemInFavorites}
            onBannerError={handleOnBannerError}
            onBannerLoad={handleOnBannerLoad}
            hideButtons={hideButtons}
          />
        );

      default:
        return item.activityType === 'nanoCourse' ? (
          <Nano
            {...item}
            itemId={item.id}
            onShareClick={handleShareClick}
            onFavoritesClick={handleFavoritesClick}
            onOpenClick={onOpenClick}
            isInFavorites={isItemInFavorites}
            onBannerError={handleOnBannerError}
            onBannerLoad={handleOnBannerLoad}
            hideButtons={hideButtons}
          />
        ) : (
          <Lo
            {...item}
            title={item.itemSource === 'externalCourse' && item[externalCourseTitleFieldName]?.length ? item[externalCourseTitleFieldName] : item.title}
            summary={item.itemSource === 'externalCourse' && item[externalCourseSummaryFieldName]?.length ? item[externalCourseSummaryFieldName] : item.summary}
            itemId={item.id}
            onShareClick={handleShareClick}
            onFavoritesClick={handleFavoritesClick}
            onAttachClick={handleAttachClickProp}
            onOpenClick={onOpenClick}
            isInFavorites={isItemInFavorites}
            onBannerError={handleOnBannerError}
            onBannerLoad={handleOnBannerLoad}
            hideButtons={hideButtons}
          />
        );
    }
  };

  return (
    <div className={styles.container} style={{ maxHeight: isMobileView ? '100vh' : !innerPopup ? '1000vh' : 'calc(100vh - 3rem)' }}>
      <div className={`${styles.loader} ${itemLoadData.isLoading || itemLoadData.isErrorLoading ? styles.on : ''} ${isFavoritesFormOnly ? styles.isFavoritesFormOnly : ''}`}>
        <div className={`${styles.preloader} ${itemLoadData.isLoading ? styles.on : ''}`}>
          <Preloader />
        </div>
        <div className={`${styles.loader_error} ${itemLoadData.isErrorLoading ? styles.on : ''}`}>
          <Title level={1}>{getGenderFormatMessage('item_preview_loade_error_title')}</Title>
          <div>{getGenderFormatMessage('item_preview_loade_error_text')}</div>
          <div>
            <Button onClick={reloadItem} type="primary">
              {getGenderFormatMessage('item_preview_reloade_btn')}
            </Button>
          </div>
        </div>
      </div>
      <HorizontalSwipeLayout
        className={`${styles.horizontalSwipeLayout} ${styles.item_content} ${itemLoadData.isReady ? styles.on : ''}`}
        slideClassName={styles.slide}
        startHeight={isFavoritesFormOnly ? '32.0625rem' : '45.625rem'}
        index={!itemLoadData.isReady ? -1 : innerPopup && !isFavoritesFormOnly ? 1 : 0}
        slides={
          isFavoritesFormOnly
            ? [
              <div>
                {item && (
                  <FavoritesForm
                    onClose={handleFavoritesInnerPopupClose}
                    setPopupClass={setPopupClass}
                    onTotalClose={onClose}
                    favoritesData={favorites}
                    userId={info?.userId}
                    itemName={item.title}
                    itemId={item.id}
                    itemExternalId={item.externalContextId}
                    itemType={item.type}
                    itemUrl={item.contextURL}
                    itemAgeGrades={item.ageGrades}
                    itemDisciplines={item.disciplines}
                  />
                )}
              </div>
            ]
            : [
              <div>{item && <>{renderItemTypeSwitch(item)}</>}</div>,
              <div style={isMobileView && lastInnerPopup === 'task' ? { height: '100%' } : {}}>
                {lastInnerPopup === 'audiences' && item && <AudienceForm onClose={handleInnerPopupClose} />}
                {lastInnerPopup === 'favorites' && item && (
                  <FavoritesForm
                    onClose={handleFavoritesInnerPopupClose}
                    setPopupClass={setPopupClass}
                    onTotalClose={onClose}
                    favoritesData={favorites}
                    userId={info?.userId}
                    itemName={item.title}
                    itemId={item.id}
                    itemExternalId={item.externalContextId}
                    itemType={item.type}
                    itemUrl={item.contextURL}
                    itemAgeGrades={item.ageGrades}
                    itemDisciplines={item.disciplines}
                  />
                )}
                {lastInnerPopup === 'task' && item && (
                  <div
                    style={{
                      maxHeight: '100vh',
                      margin: '0 auto',
                      position: 'relative'
                    }}
                  >
                    <TaskForm
                      {...item}
                      onClose={handleInnerPopupClose}
                      setPopupClass={setPopupClass}
                      onTotalClose={onClose}
                      // setCreateTaskSuccess={setCreateTaskSuccess}
                      layout={isMobileView ? 'popup' : 'inline'}
                    />
                  </div>
                )}
                {lastInnerPopup === 'share' && item && (
                  <SocialShare
                    headerTitle={getGenderFormatMessage('shareForm_shareWithTeachers')}
                    title={item.title}
                    url={`${window.location.origin}/${locale}/item/${itemId}`}
                    shareList={['link', 'whatsapp']}
                    layout="inline"
                    messageLineText={getGenderFormatMessage('shareForm_loMessage')}
                    whatsappText={getGenderFormatMessage('shareForm_whatsapp_startText')}
                    onClose={onClose}
                    onBack={handleInnerPopupClose}
                  />
                )}
              </div>
            ]
        }
      />
    </div>
  );
};

PreviewContent.propTypes = {
  itemId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onInnerPopupChange: PropTypes.func,
  onItemReady: PropTypes.func
};

export default PreviewContent;
